
import NavigationBar from "@/components/NavigationBar.vue";
import Modal from "@/components/ModaStatus.vue";
import Splash from "@/components/common/SplashComponent.vue";
import useGlobalLoading from "@/composables/useGlobalLoading";

export default {
  components: {
    NavigationBar,
    Splash,
    Modal,
  },
  setup() {
    return {
      isLoading: useGlobalLoading().isLoading,
    };
  },
};
