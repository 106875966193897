import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Transition as _Transition, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container pb-5 user-onboard" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavigationBar = _resolveComponent("NavigationBar")!
  const _component_Modal = _resolveComponent("Modal")!
  const _component_Splash = _resolveComponent("Splash")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!$setup.isLoading)
      ? (_openBlock(), _createBlock(_component_NavigationBar, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_Modal),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        ($setup.isLoading)
          ? (_openBlock(), _createBlock(_component_Splash, { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_view)
    ])
  ], 64))
}