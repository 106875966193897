import { readonly, ref } from "vue";
import { modalTypes } from "@/common";

const isVisible = ref(false);
const isSuccessModal = ref(false);
const errorMessage = ref("");
const modalTypeModal = ref(modalTypes.DEFAULT);
const actionModal = ref(null);
const canLinkNemLogin = ref(false);

export default function () {
  function success(modalType: number = modalTypes.DEFAULT, action: any = null, isShowLinkToNemLoginAct: boolean = false) {
    isVisible.value = true;
    isSuccessModal.value = true;
    modalTypeModal.value = modalType;
    actionModal.value = action;
    canLinkNemLogin.value = isShowLinkToNemLoginAct
  }

  function fail(err: string) {
    isVisible.value = true;
    isSuccessModal.value = false;
    errorMessage.value = err;
  }

  function hide() {
    isVisible.value = false;
    errorMessage.value = "";
    actionModal.value = null;
  }
  return {
    isVisible: readonly(isVisible),
    errorMessage: readonly(errorMessage),
    isSuccess: readonly(isSuccessModal),
    modalType: readonly(modalTypeModal),
    hide,
    success,
    fail,
    actionModal: readonly(actionModal),
    canLinkNemLogin: readonly(canLinkNemLogin)
  };
}
