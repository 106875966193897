import { createI18n } from "vue-i18n";
import en from "@/i18n/en.json";
import da from "@/i18n/da.json";
import getStartingLocale from "./utils/getStartingLocale";

export const i18n = createI18n({
  locale: getStartingLocale(),
  messages: {
    en: en,
    da: da,
  },
});
