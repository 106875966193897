export interface ILocale {
  code: string;
  name: string;
}

const locales: ILocale[] = [
  { code: "en", name: "English" },
  { code: "da", name: "Dansk" },
];

export default locales;
