
import { modalTypes } from "@/common";
import useGlobalModal from "@/composables/useGlobalModal";
import VDButton from "@/components/common/VDButton.vue";

export default {
  components: { VDButton },
  setup() {
    return {
      modalTypes,
      isVisible: useGlobalModal().isVisible,
      isSuccess: useGlobalModal().isSuccess,
      errorMessage: useGlobalModal().errorMessage,
      modalType: useGlobalModal().modalType,
      action: useGlobalModal().actionModal,
      canLinkNemLogin: useGlobalModal().canLinkNemLogin
    };
  },
};
