import getBrowserLocale from "./getBrowserLocale";
import supportedLocales from "./supported-locales";

export default function getStartingLocale() {
  const browserLocale = getBrowserLocale();
  const startingLang = browserLocale;
  if (supportedLocales.find((value) => value.code == startingLang)) {
    return startingLang;
  } else {
    return  "en";
  }
}
