import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "media-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_Button = _resolveComponent("Button")!
  const _component_o_icon = _resolveComponent("o-icon")!
  const _component_o_button = _resolveComponent("o-button")!
  const _component_o_dropdown_item = _resolveComponent("o-dropdown-item")!
  const _component_o_dropdown = _resolveComponent("o-dropdown")!

  return (_openBlock(), _createBlock(_component_o_dropdown, {
    "aria-role": "list",
    modelValue: $setup.currentLanguage,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.currentLanguage) = $event)),
    mobileModal: false
  }, {
    trigger: _withCtx(({ active }) => [
      _createVNode(_component_o_button, { variant: "primary" }, {
        default: _withCtx(() => [
          _createVNode(_component_Button, { class: "primary inverted ms-auto align-items-center" }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, {
                icon: ['fas', 'globe'],
                class: "d-flex align-items-center justify-content-center rounded-circle",
                width: "30",
                height: "30"
              }),
              _createElementVNode("span", null, _toDisplayString($setup.currentLanguage.code), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_o_icon, {
            icon: active ? 'caret-up' : 'caret-down'
          }, null, 8, ["icon"])
        ]),
        _: 2
      }, 1024)
    ]),
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.languages, (language) => {
        return (_openBlock(), _createBlock(_component_o_dropdown_item, {
          key: language.code,
          value: language
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: "media",
              onClick: ($event: any) => ($setup.localeSelected(language))
            }, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("span", null, _toDisplayString(language.name), 1)
              ])
            ], 8, _hoisted_1)
          ]),
          _: 2
        }, 1032, ["value"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["modelValue"]))
}