
import { ref } from "vue";
import { i18n } from "@/i18n";
import locales, { ILocale } from "@/i18n/utils/supported-locales";

export default {
  setup() {
    const currentLanguage = ref({ code: i18n.global.locale, name: "" });

    return {
      currentLanguage,
      languages: locales,
      localeSelected: (locale: ILocale) => {
        i18n.global.locale = locale.code as "en" | "da";
      },
    };
  },
};
