import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createBlock(_component_Button, {
    disabled: _ctx.isLoading || _ctx.isDisabled,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click', $event))),
    class: _normalizeClass(_ctx.variant)
  }, {
    default: _withCtx(() => [
      (_ctx.icon || _ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
            key: 0,
            class: "me-2",
            spin: _ctx.isLoading,
            icon: _ctx.isLoading ? ['fas', 'spinner'] : ['fas', _ctx.icon]
          }, null, 8, ["spin", "icon"]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["disabled", "class"]))
}